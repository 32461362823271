










































































































































@import '@design';

.section--layout {
  padding-top: $size-grid-padding * 1;
  padding-bottom: $size-grid-padding * 1;
  &.no-img {
    padding-top: $size-grid-padding * 3;
    padding-bottom: $size-grid-padding * 3;
  }
  img {
    width: 100%;
  }
}

.section--inner-title {
  border-bottom: 2px solid $color-db-purple;
}

.text-content {
  font-size: 20px;
  letter-spacing: 0.012em;
}

.subheading {
  // TODO (ESS): dont hardcode text
  font-family: 'Asap', sans-serif;
  font-style: italic;
}
